<template>
  <div class="tingXiaL">
    <div class="tingXiaL-t">
      <div class="search-l">
        <div class="img"><img src="../img/search.png" alt="" /></div>
        <div class="input">
          <v-input v-model="topicTitle" placeholder="输入关键字"></v-input>
        </div>
      </div>
      <div class="search-r" @click="toSearch">搜索</div>
    </div>
    <div class="tingXiaL-main">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="list-content"
          v-for="(item, index) in listData"
          :key="index"
          @click="toRouteTitle(item)"
        >
          <div class="detail">
            <div class="detail-l">详情</div>
            <div class="detail-r"><img src="../img/arrow.png" alt="" /></div>
          </div>
          <div class="item-t">
            <div class="item-tl"><img :src="item.topicPicture" alt="" /></div>
            <div class="item-tr">
              <div class="title">{{ item.topicTitle || "" }}</div>
              <div class="h3">
                {{ item.nowStatus || "" }}
              </div>
            </div>
          </div>
          <div class="item-b">
            <div class="item-bl">{{ item.createTime }}</div>
            <div class="item-br">
              <div class="left-wrap">
                <div class="iconText">
                  <div class="icon">
                    <img src="../img/icon-read.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.readCount }}</span>
                  </div>
                </div>
                <div class="iconText">
                  <!-- <div class="icon">
                    <img src="../img/icon-msg.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.commentCount }}</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTopicListURL } from "../api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "partyBuilding",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      topicTitle: "",
      listData: [],
      bannerList: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {
    toSearch() {
      this.requestData = {
        curPage: 1,
        pageSize: 10,
      };
      this.listData = [];
      this.getPartyList();
    },
    toRouteTitle(row) {
      this.$router.push({
        name: "tingXiaLDetail",
        query: {
          id: row.id,
        },
      });
    },
    onLoad() {
      this.getPartyList();
    },
    async getPartyList() {
      let res = await this.$axios.get(getTopicListURL, {
        params: {
          topicTitle: this.topicTitle,
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
          status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
          topicType: "59", // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
          curPage: this.requestData.curPage++,
          pageSize: this.requestData.pageSize,
          sortField: "NEW",
        },
      });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tingXiaL {
  min-height: 100vh;
  .tingXiaL-t {
    display: flex;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    align-items: center;
    padding: 20px 48px 20px 40px;
    justify-content: space-between;
    .search-l {
      width: 590px;
      height: 68px;
      background: #f5f8fb;
      border-radius: 34px;
      display: flex;
      align-items: center;
      padding: 14px 30px;
      box-sizing: border-box;
      .input {
        flex: 1;
      }
      /deep/ .van-cell {
        padding: 0;
        background: none;
      }
      .img {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .search-r {
      font-weight: 400;
      font-size: 28px;
      color: #fb8133;
      line-height: 40px;
    }
  }
  .tingXiaL-main {
    padding: 128px 40px;
    .list-content {
      height: 274px;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 17px;
      margin-bottom: 20px;
      padding: 32px 16px 16px 18px;
      box-sizing: border-box;
      position: relative;
      .detail {
        width: 82px;
        position: absolute;
        top: 42px;
        right: 0;
        display: flex;
        align-items: center;
        .detail-l {
          font-weight: 500;
          font-size: 24px;
          color: #fb8133;
          line-height: 34px;
        }
        .detail-r {
          width: 28px;
          height: 28px;
          font-size: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .item-t {
      display: flex;
      justify-content: space-between;
      .item-tl {
        width: 248px;
        flex-shrink: 0;
        margin-right: 18px;
        height: 186px;
        border-radius: 17px;
        img {
          width: 100%;
          border-radius: 17px;
          height: 100%;
        }
      }
      .item-tr {
        flex: 1;
        min-width: 0;
        .title {
          width: 280px;
          font-weight: 700;
          font-size: 35px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #1a1c34;
          line-height: 48px;
        }
        .h3 {
          font-weight: 400;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 36px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          margin-top: 16px;
        }
      }
    }
    .item-b {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      .item-bl {
        font-weight: 400;
        font-size: 24px;
        color: #aaaaaa;
        line-height: 34px;
      }
      .left-wrap {
        display: flex;
        align-items: center;
        .iconTag {
          flex: 1;
          overflow: hidden;
          margin-right: 8px;
          display: flex;
          align-items: center;
          > span {
            height: 32px;
            display: flex;
            align-items: center;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            border-radius: 4px;
            font-size: 24px;
            background: #fff3eb;
            border-radius: 8px;
            border: 2px solid #ffc7a4;
            padding: 0px 8px;
            color: #fe6f16;
          }
        }
        .iconText {
          display: flex;
          color: #a8a8a8;
          align-items: center;
          &:not(:last-child) {
            margin-right: 32px;
          }
          .icon {
            display: flex;
            align-items: center;
            &.icon-up {
              > img {
                margin-top: 4px;
              }
            }
            > img {
              width: 36px;
              height: 36px;
            }
          }
          .text {
            margin-left: 8px;
            display: flex;
            align-items: center;
            height: 36px;
            line-height: 36px;
            span {
              &.hasPraised {
                color: #ff853a;
              }
              font-size: 24px;
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
</style>
